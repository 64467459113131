@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.error {
  display: inline-flex;
  align-items: center;
  text-align: left;
  color: rgba($colorRed, 0.5);
  font-size: 11px;
  line-height: 14px;
  margin-left: 20px;
}

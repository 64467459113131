@import '/src/app/styles/vars.scss';

.title {
  margin: 0 0 35px 0;

  @media (min-width: $tabletWidth) {
    margin-bottom: 60px;
  }

  &.middle {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 10px;

    @media (min-width: $tabletWidth) {
      font-size: 37px;
      line-height: 40px;
      margin-bottom: 30px;
    }
  }
}

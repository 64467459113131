@import '/src/app/styles/vars.scss';

.fieldset {
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;

  &.bordered {
    padding-bottom: 25px;
    box-shadow: 0 -1px 0 0 inset $colorGray;

    @media (min-width: $tabletWidth) {
      padding-bottom: 40px;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 33px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.row {
  display: flex;
  align-items: center;

  &.column {
    flex-direction: column;
  }

  &.duo {
    gap: 10px 20px;
    flex-wrap: wrap;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    & > div {
      flex-basis: calc(100% - 40px);

      @media (min-width: $tabletWidth) {
        flex-basis: calc(50% - 30px);
      }
    }
  }
}

.subText {
  margin: 0;
  margin-top: -6px;
  margin-bottom: 15px;
}

.subTexts {
  margin: 0;

  label + & {
    margin-top: -6px;
  }

  & + .subError {
    margin-top: 10px;
  }
}

.subError {
  margin: 0;
  margin-top: -6px;
  margin-bottom: 15px;
  color: $colorRed;

  &:empty {
    display: none;
  }
}

.list_action {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  & + & {
    margin-top: 20px;
  }

  label {
    width: calc(100% - 40px);
    font-size: 14px;
    margin-block: 5px 10px;
  }
}

.list_content {
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.list_button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  fill: $colorGray;

  &:hover {
    fill: $colorBlack;
  }
}

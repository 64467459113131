@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.navbar {
  position: relative;
  display: flex;
  align-items: center;

  &.desktop {
    display: none;

    @media (min-width: $tabletWidth) {
      display: flex;
    }
  }

  &.index {
    @media (min-width: $tabletWidth) {
      margin-top: 28px;
      order: 1;
    }

    @media (min-width: $laptopWidth) {
      gap: 26px;
      margin-top: 0;
      position: relative;
      top: -10px;
      order: 0;
    }
  }

  &.cabinet {
    flex-direction: column;
    align-items: flex-start;
    left: 0;

    @media (min-width: $tabletWidth) {
      flex-direction: row;
      align-items: center;
    }
    @media (min-width: $laptopWidth) {
      flex-grow: 1; //Добавил
    }
  }
}

.mobileNav {
  display: flex;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  .index & {
    flex-direction: column;
    gap: 13px;
  }
  .cabinet & {
    display: none;
  }

  @media (min-width: $tabletWidth) {
    .index & {
      flex-direction: row;
      gap: 26px;
    }
    .cabinet & {
      flex-direction: column;
    }
  }

  @media (min-width: $laptopWidth) {
    flex-direction: row;
    position: static;
    opacity: 1;
    visibility: visible;
    flex-grow: 1; //Добавил

    .cabinet & {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.link {
  position: relative;
  z-index: 1;
  color: $colorBlack;
  font-weight: 700;
  white-space: nowrap;
  width: fit-content;

  &.btn {
    @include reset-btn;
  }
  .index & {
    font-size: 14px;
  }

  &.linkMin {
    font-size: 14px;
  }

  &.linkOffer {
    margin-left: 0;

    @media (min-width: $laptopWidth) {
      margin-left: 50px;
    }
  }

  @media (min-width: $tabletWidth) {
    &,
    .index & {
      font-size: 18px;
      line-height: 20px;
    }
  }
}

.linkNewTest {
  width: 142px;
  margin-left: 0px;
  white-space: nowrap;
  margin-top: 15px;
  margin-bottom: 20px;

  @media (min-width: $tabletWidth) {
    margin-top: 0;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 0;
  }
}

.linkBalance {
  margin-left: 0;

  @media (min-width: $laptopWidth) {
    margin-left: 20px;
    margin-right: 57px;
  }
}

.linkHelp {
  @include reset-btn;
}

.currentLink {
  &::after {
    @include linkActiveElement;
  }
}

.linkSup {
  position: relative;
  display: flex;
  align-items: center;
}

.supText {
  position: absolute;
  bottom: 2px;
  left: calc(100% + 2px);
  font-size: 9px;
  white-space: nowrap;
  font-weight: 400;
}

.linkExit {
  color: rgba($colorBlack, 0.5);
  margin-top: 5px;

  &:hover {
    color: $colorBlack;
  }

  @media (min-width: $laptopWidth) {
    margin-top: 0;
    margin-left: auto;
  }
}

.nameWrap {
  position: relative;
}

.moreBtn {
  display: none;

  @media (min-width: $tabletWidth) {
    @include reset-btn;
    position: relative;
    left: 0;
    display: flex;
    align-items: center;
    padding: 10px 0;
    gap: 4px;
    border: none;
    background-color: transparent;
    font-weight: 700;
  }

  @media (min-width: $laptopWidth) {
    display: none !important;
  }
}

.moreSupBtn {
  @include reset-btn;
  position: relative;
  display: flex;
  padding: 10px 8px;
  gap: 6px;
  border: none;
  background-color: transparent;
  font-weight: 700;
  font-size: 15px;

  @media (min-width: $tabletWidth) {
    display: none;
  }

  @media (min-width: $laptopWidth) {
    display: flex !important;
  }
}

.moreBtnInMenu {
  @include reset-btn;
  position: relative;
  display: flex;
  padding: 10px 0px;
  gap: 6px;
  border: none;
  background-color: transparent;
  font-weight: 700;
  font-size: 15px;

  @media (min-width: $laptopWidth) {
    display: none !important;
  }
}

.mobileMenu {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: -3px;

  @media (min-width: $tabletWidth) {
    gap: 10px;
    margin-left: 0;
  }
}

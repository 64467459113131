@import './fonts.scss';
@import './normalize.scss';
@import './vars.scss';

:root {
  --pink: #ff00ff;
  --blue: #00aaff;
  --window-max-height: 100vh;
  --wrapper-padding: 20px;

  @media (min-width: $tabletWidth) {
    --wrapper-padding: 30px;
  }
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html {
  // margin-left: calc(100vw - 100%);
}
html,
body {
  height: 100%;
  scrollbar-gutter: stable;
}
body {
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: $mainFontName;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $colorBlack;
  background-color: $mainBgColor;
  font-size: $bodyFontSize;
  line-height: 20px;
  font-weight: 400;

  &::after {
    content: '';
    display: block;
    background-color: rgba($colorBlack, 0.3);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.body {
  &--close {
    height: 100vh;
    overflow-y: hidden;
  }
  // &--close-win {
  //   padding-right: 17px; /* Avoid width reflow */
  // }
  &--modal {
    &::after {
      opacity: 1;
      visibility: visible;
    }
  }
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main {
  flex: 1 0 auto;
}

.wrapper {
  min-width: 340px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--wrapper-padding);
  padding-right: var(--wrapper-padding);

  @media (min-width: $tabletWidth) {
    max-width: 680px;
  }
  @media (min-width: $laptopWidth) {
    max-width: 1000px;
  }
}

.scroll-block::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

.scroll-block::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #c3c3c3;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

a {
  text-decoration: none;
  transition: color 0.3s ease;
  color: $colorBlack;
}

button {
  color: $colorBlack;
  font-size: $bodyFontSize;
  font-family: $mainFontName;

  &:not(:disabled) {
    cursor: pointer;
  }
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;

  tr {
    vertical-align: top;
  }

  td {
    word-break: break-word;
    border-top: 1px solid $borderColor;
    padding: 15px 0;
  }

  tr:last-of-type td {
    border-bottom: 1px solid $borderColor;
  }

  th {
    color: rgba($colorBlack, 0.5);
    font-size: 11px;
    line-height: 15px;
    text-align: left;
    font-weight: normal;
    padding: 15px 0;
  }
}

h1,
h2,
h3 {
  text-align: left;
  font-weight: 900;
}

h1 {
  font-size: 28px;
  line-height: 30px;
  @media (min-width: $tabletWidth) {
    font-size: 46px;
    line-height: 50px;
  }
}

h2 {
  font-size: 28px;
  line-height: 30px;
}

h3 {
  font-size: 18px;
  line-height: 20px;
}

h4 {
  font-size: 14px;
  line-height: 20px;
}

@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.wrap {
  width: 20px;
  height: 20px;

  .popper {
    position: absolute;
    max-width: 340px;
    padding: 20px;
    box-shadow: $popupBoxShadow;
    background: $colorWhite;
    border-radius: 5px;
    text-align: left;
    color: $colorBlack;

    @media (min-width: $tabletWidth) {
      max-width: 360px;
      padding: 24px 30px;
    }
  }

  .arrow {
    height: 20px;
    width: 20px;
  }

  .btn {
    @include reset-btn;
    height: 20px;

    &.active {
      .icon {
        fill: $colorBlack;
      }
    }
    .icon {
      fill: rgba($colorBlack, 0.2);
      transition: fill $trParam;
      pointer-events: all;
      will-change: auto;
      cursor: pointer;
      zoom: normal;

      @media (min-width: $mobileWidth) {
        &:hover {
          fill: $colorBlack;
        }
      }
    }
  }

  .text {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
}

@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.spiner {
  display: flex;
  width: 100%;
  min-height: 100%;
  padding: 30px 0;
  align-items: center;
  justify-content: center;

  &.top {
    align-items: flex-start;
  }
}

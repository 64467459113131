@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;

  @media (min-width: $tabletWidth) {
    gap: 20px;
  }
}

.dropzone {
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 140px;

  @media (min-width: $tabletWidth) {
    width: 100%;
  }
}

.trashBtn {
  @include reset-btn;
  margin-left: auto;
  fill: $colorGray;
  transition: $tr;
  height: 20px;

  &:hover {
    fill: $colorBlack;
  }
}

.title {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  margin: 0;
}

.body {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  border: 1px solid $borderColor;
  border-radius: $borderRadius;
  overflow: hidden;
  outline: 1px solid transparent;
  transition: $tr;
  cursor: pointer;

  @media (min-width: $tabletWidth) {
    width: 300px;
    height: 300px;
  }

  &.error {
    border-color: $colorRed;
    // outline: 1px solid $colorRed;
  }

  &.hover,
  &:hover {
    outline: 1px solid $colorBlack;
    border-color: $colorBlack;
  }

  @media (min-width: $tabletWidth) {
    &::after {
      --width: 19px;
      content: '';
      display: block;
      position: absolute;
      top: var(--width);
      left: var(--width);
      width: calc(100% - var(--width) * 2);
      height: calc(100% - var(--width) * 2);
      border: 1px dashed #808080;
    }
  }
}

.text {
  position: relative;
  top: -7px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  user-select: none;
}

.textMobile {
  display: block;

  @media (min-width: $tabletWidth) {
    display: none;
  }
}

.textDesktop {
  display: none;

  @media (min-width: $tabletWidth) {
    display: block;
  }
}

.subText {
  position: relative;
  top: 4px;
  margin: 0;
  margin-top: 2px;
  font-size: 11px;
  line-height: 15px;
  color: rgba($colorBlack, 0.5);
}

.imgWrap {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  background-color: $colorWhite;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.list {
  @include reset-list;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media (min-width: $tabletWidth) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $laptopWidth) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.item {
  width: 100%;
  background-color: #fafafa;
  border-radius: 10px;
  max-width: 100%;
  padding-top: 20px;
  padding-right: 40px;
  padding-bottom: 30px;
  padding-left: 30px;

  @media (min-width: $tabletWidth) {
    padding: 0px 29px;
    padding-top: 25px;
    padding-bottom: 29px;
  }
}

.title {
  font-size: 18px;
  line-height: 30px;
  font-weight: 900;
  margin: 0;
  margin-bottom: 12px;

  br {
    display: none;
  }

  @media (min-width: $tabletWidth) {
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 25px;

    br {
      display: block;
    }
  }
}

.text {
  margin: 0;

  font-size: 14px;
  line-height: 20px;
}

.buttons {
  display: flex;
  gap: 4px;
  margin-top: 25px;
}

.step {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid $colorBlack;
  margin-top: 25px;
  font-weight: 700;
  font-size: 14px;
}

.img {
  display: block;
  margin-bottom: 10px;

  @media (min-width: $tabletWidth) {
    margin-bottom: 20px;
  }
}

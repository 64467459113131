@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

$trPopup: opacity, visibility, transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);

.popup {
  --offsetTop: 30px;
  position: absolute;
  z-index: 0;
  top: calc(100% + var(--offsetTop));
  right: 0;
  width: 100%;
  padding: 24px 20px;
  box-shadow: $popupBoxShadow;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.95);
  background: $colorWhite;
  &.center {
    inset: auto;
  }
  & > * {
    transform: scale(1.000001);
  }

  @media (min-width: $tabletWidth) {
    width: 360px;
    padding: 24px 30px;
    transition: $trPopup;
  }

  &--auth {
    $offset: calc(var(--wrapper-padding) * -1);
    padding: 23px 20px 30px;
    // width: 100vw;

    @media (min-width: $tabletWidth) {
      padding: 23px 30px 30px;
    }

    &.header {
      right: -11px;

      @media (max-width: $tabletWidth) {
        right: $offset;
        top: 30px;
        width: 100vw;
      }
    }
    &.landing {
      right: auto;
      // left: $offset;
      left: 0;

      &.top {
        top: calc(100% + 30px);
      }
      &.bottom {
        top: auto;
        bottom: calc(100% + 30px);
      }
    }
  }
  &--static {
    position: static;
  }

  &--navbar {
    @include popupShow();
    position: static;
    box-shadow: none;
    padding: 0;
    max-width: 100%;

    @media (min-width: $tabletWidth) {
      @include popupHide();
      box-shadow: $popupBoxShadow;
      position: absolute;
      padding: 15px 20px;
      max-width: 180px;
      top: 35px;
      right: -25px;
    }
  }

  &--comment {
    --offsetTop: 0px;
    @include popupHide();
    box-shadow: $popupBoxShadow;
    position: absolute;
    padding: 15px 20px;
    max-width: 180px;
    top: -10px;
    right: -10px;
    width: 180px;
  }

  &--show {
    @include popupShow();
    transform: scale(1) !important;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 100%;
    height: var(--offsetTop);
    pointer-events: none;
  }
}

.button {
  @include reset-btn;
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
  fill: #cccccc;
  transition: $tr;

  &:hover {
    fill: $colorBlack;
  }

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    display: block;
    width: 40px;
    height: 40px;
  }
}

.title {
  margin: 0;
  margin-bottom: 13px;
  font-size: 18px;
  line-height: 20px;
}

.arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  transition: $trPopup;
  visibility: visible;
  transform: translate3d(0px, 0px, 0px);

  &.left {
    border-width: 10px 10px 10px 0;
    border-color: transparent $colorWhite transparent transparent;
    left: -10px;
    top: 25px;
  }

  &.top {
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent $colorWhite transparent;
    left: 50%;
    margin-left: -10px;
    top: -10px;
  }
}

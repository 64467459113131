@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.mobile {
  @media (min-width: $tabletWidth) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (min-width: $tabletWidth) {
    display: table;
  }
}

.th {
  padding-top: 0;

  &.date {
    width: 120px;
  }
  &.descr {
    width: 240px;
  }
  &.sum {
    width: 80px;
  }

  &.balance {
    width: 80px;
    text-align: center;
  }
}

.td {
  vertical-align: top;

  &.descr {
    line-height: 20px;
    padding-right: 30px;
  }

  &.balance {
    white-space: nowrap;
    vertical-align: top;

    @media (min-width: $tabletWidth) {
      vertical-align: top;
      text-align: left;
      padding-left: 20px;
    }
  }

  .sum {
    display: block;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 700;
  }
}

.success {
  color: $colorGreen;
}

.error {
  color: $colorRed;
}

.linkDownload {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 700;
}

.mobileTable {
  .mobileTh {
    display: flex;
    gap: 10px;
  }
  .mobileTd {
    padding-right: 20px;
    .descr {
      width: auto;
    }
    .date {
      display: block;
      width: 100%;
    }
    .doc {
      width: 100%;
    }
  }
  .footer {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .sum {
      width: 80px;
    }
  }
}

.loading {
  opacity: 0.5;
  pointer-events: none;
}

@import '/src/app/styles/vars.scss';

.content {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (min-width: $laptopWidth) {
    flex-wrap: nowrap;
  }
}

@import '/src/app/styles/vars.scss';

.radio {
  display: flex;
  align-items: center;
}

.label {
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
  padding-left: 5px;
  user-select: none;
  cursor: pointer;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='radio'] {
    --active: #000;
    --active-inner: #fff;
    --hover-inner: #000;
    --border: #e5e5e5;
    --border-hover: #000;
    --background: #fff;
    --disabled: #e5e5e5;
    --disabled-inner: #e5e5e5;
    --disabled-text-color: #808080;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 19px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    flex-shrink: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
        opacity var(--d-o, 0.2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: 0.3s;
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          border-width: 2px;
          --bc: var(--active);
        }
      }
    }
    &:focus {
      border: 1px solid var(--border-hover);
    }
    &:hover {
      &:checked {
        &:after {
          border-color: var(--active-inner);
        }
        &:not(:disabled) {
          --bc: var(--active-inner);
        }
      }
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: 1;

      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);

        &:after {
          border-color: #797979;
        }
      }
      & + label {
        color: $colorGrayTwo;
        cursor: not-allowed;
      }
    }
    &:not(.switch) {
      width: 19px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
  }

  input[type='radio'] {
    --disabled-after-inner: #727272;
    --border: #e5e5e5;
    border-radius: 50%;

    &:after {
      width: 6px;
      height: 6px;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      transform: translate(-50%, -50%);
    }
    &:checked {
      --s: 0.31;
      --bc: var(--active);
    }
    &:hover {
      &::after {
        background: var(--active-inner);
      }
      &:not(:disabled) {
        border-color: var(--border-hover);
      }
      &:checked {
        &:after {
          background: var(--active-inner);
        }
      }
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: 1;

      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);

        &:after {
          background: var(--disabled-after-inner);
        }
      }
    }
  }
}
input[type='radio'].radio--error {
  border-color: $inputErrorBorder;
}

@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.item {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-left: 35px;
  margin-bottom: 20px;

  .quote {
    position: relative;
    margin: 0;
    font-size: 18px;
    font-weight: 900;
    padding-top: 8px;

    p {
      margin: 0;
    }

    &::before {
      content: '\2033';
      position: absolute;
      left: -35px;
      top: -6px;
      font-family: Inter;
      font-size: 55px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: normal;
      line-height: 60px;
      color: rgba(0, 0, 0, 0.2);
    }
  }

  .author {
    margin: 0;
    margin-top: 12px;
  }
}

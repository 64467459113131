@import '/src/app/styles/vars.scss';

.col {
  position: relative;
  flex-grow: 1;
  max-width: 100%;
}

.left {
  max-width: 620px;
  width: 100%;

  @media (min-width: $laptopWidth) {
    margin-right: 100px;
  }
}

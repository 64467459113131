@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.buttonTrigger {
  @include reset-btn;
}

.content {
  z-index: 13;
  max-width: 340px;
  padding: 24px 20px;
  box-shadow: $popupBoxShadow;
  background: $colorWhite;
  border-radius: $borderRadiusBig;
  text-align: left;
  color: $colorBlack;

  @media (min-width: $tabletWidth) {
    max-width: 360px;
    padding: 24px 30px;
  }

  .buttonClose {
    @include reset-btn;
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    fill: #cccccc;
    transition: fill 0.3 ease;
    outline: none;

    &:hover {
      fill: $colorBlack;
    }

    &::before {
      content: '';
      position: absolute;
      top: -50%;
      left: -50%;
      display: block;
      width: 40px;
      height: 40px;
    }
  }

  .title {
    margin: 0;
    margin-bottom: 13px;
    font-size: 18px;
    line-height: 20px;
    font-weight: 900;
  }

  .text {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
}

@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.buttons {
  &.mobile {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    margin-bottom: 15px;
    gap: 15px;
  }
  &.desktop {
    display: none;

    @media (min-width: $tabletWidth) {
      display: flex;
      align-items: center;
      gap: 25px;
    }
  }
}

.loginBtn {
  padding-left: 12px;
  padding-right: 18px;
}
.regBtn {
  padding-right: 0 !important;

  .mobile & {
    padding-left: 0;
    padding-right: 0;
  }
}
.bordered {
  position: relative;

  &::after {
    @include linkActiveElement;
  }
}

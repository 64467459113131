@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.wrap {
  position: relative;
  top: 0;
  margin: 4px 0 0 auto;

  @media (min-width: $tabletWidth) {
    display: none;
  }
}

.popup {
  width: 180px;
  padding: 15px 20px 3px;
  top: calc(100% + 5px);
  right: 0;

  &.cabinet {
    padding-bottom: 13px;
  }
}

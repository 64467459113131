@import '/src/app/styles/mixins.scss';
@import '/src/app/styles/vars.scss';

.pagination {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
  max-width: 260px;
  justify-content: space-between;

  @media (min-width: $tabletWidth) {
    max-width: 100%;
    flex-wrap: nowrap;
    gap: 48px;
  }

  .btn {
    gap: 4px;

    &.empty {
      &:active,
      &:disabled {
        color: rgba($colorBlack, 0.2);
        fill: rgba($colorBlack, 0.2);
      }
    }
  }
}

.list {
  @include reset-list;
  display: flex;
  gap: 8px;
  order: -1;
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: $tabletWidth) {
    order: 0;
    margin-bottom: 0;
  }
}

.item {
  position: relative;
  color: $colorBlack;

  &:hover:not(.current) {
    .link {
      background-color: $colorBlack;
      color: $colorWhite;
    }
  }
  &.current {
    .link {
      border-color: $colorBlack;
      cursor: default;
      pointer-events: none;
    }
  }
  &.more {
    color: rgba($colorBlack, 0.5);
  }
}
.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: inherit;
  font-size: 14px;
  user-select: none;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-radius: 50%;
}

@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.footer {
  position: relative;
  margin-top: 40px;

  @media (min-width: $tabletWidth) {
    margin-top: 60px;
  }

  .inner {
    display: flex;
    flex-flow: wrap column;
    justify-content: flex-start;
    box-shadow: $borderShadowTop;
    padding-top: 25px;
    padding-bottom: 25px;

    @media (min-width: $tabletWidth) {
      flex-direction: row;
    }
  }
  .copyright {
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    margin-top: 50px;
    order: 3;

    @media (min-width: $tabletWidth) {
      margin-left: auto;
      margin-top: 0;
      order: 0;
    }
  }
  .mail {
    margin-top: 20px;

    @media (min-width: $laptopWidth) {
      margin-left: 40px;
      margin-top: 0;
    }
  }
  .list {
    display: flex;
    flex-direction: column;

    @media (min-width: $laptopWidth) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

.paymentsList {
  @include reset-list();
  display: flex;
  gap: 10px;
  margin-top: 25px;
  width: 100%;
  order: 4;

  @media (min-width: $tabletWidth) {
    order: 0;
  }

  .item {
    &:first-child {
      margin-right: 5px;
    }
  }
}

@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.progress {
  --height: 20px;
  --border-radius: 5px;

  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--height);
  background-color: rgba($colorBlack, 0.03);
  border-radius: var(--border-radius);
  overflow: hidden;
}

.subLine,
.mainLine {
  min-width: 20px;
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  transition: width $trParam;
  border-radius: inherit;
  &.noRadius {
    border-radius: 0;
  }
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
  &.default {
    background-color: $colorBlack;
  }
  &.male {
    background-color: var(--blue);
  }
  &.female {
    background-color: var(--pink);
  }
}

.subLine {
  border-radius: $borderRadius;
  z-index: 1;

  &.male {
    background-image: url("data:image/svg+xml,%3Csvg width='3' height='3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)' fill='%23fff'%3E%3Cpath d='M0 0h1v1H0zM1 1h1v1H1zM2 2h1v1H2z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h3v3H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    background-repeat: repeat;
  }
  &.female {
    background-image: url("data:image/svg+xml,%3Csvg width='3' height='3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)' fill='%23fff'%3E%3Cpath d='M0 0h1v1H0zM1 1h1v1H1zM2 2h1v1H2z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h3v3H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    background-repeat: repeat;
  }
}

@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

:root {
  --modalPadding: 15px;
  --modalBodyPadding: 40px;
  --modalBodyPaddingTop: 20px;

  --modalFixedFooterHeight: 0px;
  --modalFixedHeaderHeight: 0px;

  @media (min-width: $tabletWidth) {
    --modalBodyPaddingTop: 35px;
  }
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8000;
  padding-top: var(--modalPadding);
  padding-bottom: var(--modalPadding);
  overflow: auto;

  &--fixed {
    overflow: hidden;
  }

  &--no-scroll {
    overflow: hidden;
  }
  &--show {
    opacity: 1;
    visibility: visible;

    .modal__overlay {
      opacity: 1;
    }
  }

  &__overlay {
    @include reset-btn;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    z-index: -1;
    transition: $tr;
    font-size: 0;
    cursor: pointer;
  }
  &__body {
    position: relative;
    margin: auto;
    background-color: $colorWhite;
    max-width: 700px;
    width: 100%;
    box-shadow: $popupBoxShadow;
    border-radius: $borderRadius;
    padding: var(--modalBodyPaddingTop) var(--modalBodyPadding);
    z-index: 10;

    .modal--fixed & {
      // max-height: calc(var(--window-max-height) - (var(--modalPadding) * 2));
      height: auto;
    }

    @media (max-width: $tabletWidth) {
      max-width: 100vw;
      padding: calc(var(--modalBodyPadding) / 2);
      padding-top: var(--modalBodyPaddingTop);
    }
  }
  &__inner {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    &--adaptive {
      max-width: 100%;
      width: fit-content;
    }

    .modal--fixed & {
      overflow: auto;
      padding: 0;
      height: 100%;
      // max-height: calc(
      //   var(--window-max-height) -
      //     (
      //       (var(--modalPadding) + var(--modalBodyPaddingTop)) * 2 +
      //         var(--modalFixedHeaderHeight) + var(--modalFixedFooterHeight)
      //     )
      // );
      max-height: calc(
        100dvh - 60px - 100px - var(--modalPadding) * 2 -
          var(--modalBodyPaddingTop) * 2
      );
      padding-left: 1px;
      padding-bottom: 1px;
      padding-right: 1px;
    }

    .modal--fixed &--no-scroll {
      overflow: hidden;
    }
  }
  &__close {
    @include reset-btn;
    position: absolute;
    z-index: 4;
    top: 10px;
    right: 10px;
    fill: #ccc;

    &:hover {
      fill: $colorBlack;
    }

    &::before {
      content: '';
      position: absolute;
      top: -50%;
      left: -50%;
      display: block;
      width: 40px;
      height: 40px;
    }
  }

  &__fixed-footer {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    margin-top: 0px;
    padding-top: 30px;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 30px;
      border-top: 1px solid $borderColor;
    }

    &--min {
      padding-top: 20px;
    }
    &--no-border {
      &::before {
        display: none;
      }
    }
  }

  &__fixed-header {
    margin-top: 0;
  }

  &__title {
    font-weight: 900;
    margin: 0;
    font-size: 28px;
    line-height: 30px;
    padding-bottom: 30px;

    &.nopadding {
      padding-bottom: 0;
    }
  }

  &__description {
    p {
      margin-top: 0;
      margin-bottom: 5px;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}

/* ENTER TRANSITION */
/* Declare transition start properties*/
.modal--enter {
  transform: translateY(40px);
}

/* Declare transition properties */
.modal--enter.modal--enter-active {
  transform: translateY(0);
  transition: all 500ms ease;
}

/* EXIT TRANSITION */
.modal--exit {
  transform: translateY(0);
  display: none;
}

.modal--exit.modal--exit-active {
  transform: translateY(40px);
  display: none;
  transition: all 0.5s;
}

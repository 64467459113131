@import '/src/app/styles/vars.scss';

.text {
  max-width: 620px;
  width: 100%;

  p {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    margin: 0;

    @media (min-width: $tabletWidth) {
      font-size: 18px;
      line-height: 30px;
    }
  }
}

@import '/src/app/styles/vars.scss';

.navbarFooter {
  position: relative;
  display: flex;
  flex-flow: wrap column;
  gap: 10px 20px;

  @media (min-width: $laptopWidth) {
    flex-direction: row;
  }
}

.link {
  position: relative;
  color: $colorBlack;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  white-space: nowrap;

  span {
    position: relative;
  }

  &:global.active span::after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: $colorBlack;
    display: block;
    position: absolute;
  }
}

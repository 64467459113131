@import '/src/app/styles/vars.scss';

.label {
  display: block;
  font-weight: 900;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 17px;
  text-align: left;
}

@import '/src/app/styles/vars.scss';

.text {
  margin: 0;
  font-size: 14px;
  line-height: 20px;

  &.min {
    font-size: 14px;
    line-height: 20px;
  }

  @media (min-width: $tabletWidth) {
    font-size: 18px;
    line-height: 30px;
  }
}

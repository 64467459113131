@import '/src/app/styles/vars.scss';

.video {
  display: block;
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  pointer-events: none;
  user-select: none;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

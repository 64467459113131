@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.wrap {
  position: relative;
}

.wrapIndent {
  position: relative;
  margin-bottom: 65px;
}

.popup {
  top: calc(100% + 30px);
  left: -10px;
  padding-bottom: 30px;
  transform: scale(0);
  max-width: 360px;

  @media (min-width: $tabletWidth) {
    left: 0;
  }

  &--show {
    transform: scale(1);
  }
}

.right {
  top: calc(100% - 180px);
  left: calc(-1 * var(--wrapper-padding));
  padding-bottom: 30px;
  @media (min-width: $laptopWidth) {
    left: auto;
  }
}

.bottom {
  top: auto;
  bottom: -210px;
  left: -10px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  z-index: 2;
}

.list {
  margin-bottom: 15px;
}

.title {
  margin: 0;
  margin-bottom: 13px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 900;
}

.input {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.footerBtn {
  display: flex;
  align-items: center;
}

.time {
  margin-left: 20px;
  font-size: 11px;
  line-height: 15px;
  color: rgba($colorBlack, 0.5);
}

.offer {
  margin-left: 0;
  font-size: 11px;
  // font-weight: 700;
  // text-decoration: underline;
  line-height: 15px;
  color: rgba($colorBlack, 0.5);
  cursor: pointer;
}

.error {
  margin-left: 20px;
  font-size: 11px;
  line-height: 15px;
  color: rgba($colorRed, 0.5);
}

.select_inn {
  max-width: 300px;
}

.ozonManual {
  display: flex;
  flex-direction: column;

  p {
    margin: 0 0;
  }

  ol {
    margin: 0;
    padding: 0 0 0 15px;

    li + li {
      margin-top: 10px;
    }
    margin-bottom: 17px;
  }
}

@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

@mixin grid() {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media (min-width: $tabletWidth) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $laptopWidth) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.index {
  .pageTitle {
    margin-bottom: 40px;

    @media (min-width: $tabletWidth) {
      margin-bottom: 65px;
    }

    @media (min-width: $laptopWidth) {
      margin-bottom: 68px;
    }
  }

  .title {
    &.how {
      margin-bottom: 40px;

      @media (min-width: $tabletWidth) {
        margin-bottom: 55px;
      }
    }
    &.tariffs {
      margin-bottom: 34px;

      @media (min-width: $tabletWidth) {
        margin-bottom: 42px;
      }
    }
  }
  .text {
    max-width: 620px;

    &.index {
      margin-bottom: 25px;

      @media (min-width: $tabletWidth) {
        margin-bottom: 32px;
      }
    }
    &.testFirst {
      margin-bottom: 32px;
      @media (min-width: $tabletWidth) {
        margin-bottom: 50px;
      }
    }
    &.testSecond {
      max-width: 580px;
      margin-bottom: 25px;

      @media (min-width: $tabletWidth) {
        margin-bottom: 32px;
      }
    }
    &.compare {
      margin-bottom: 32px;

      @media (min-width: $tabletWidth) {
        margin-bottom: 42px;
      }
    }
    &.tariffs {
      margin-bottom: 37px;

      @media (min-width: $tabletWidth) {
        margin-bottom: 50px;
      }
    }
  }

  .testsList {
    margin-bottom: 35px;

    @media (min-width: $tabletWidth) {
      margin-bottom: 50px;
    }
  }
  .testItem {
    padding: 0px 29px;
    padding-top: 20px;
    padding-bottom: 25px;
    max-height: 420px;

    @media (min-width: $tabletWidth) {
      padding-top: 27px;
      padding-bottom: 24px;
      padding-left: 32px;
      padding-right: 26px;
    }
  }
  .compareList {
    @include grid();
    margin-bottom: 35px;
    row-gap: 30px;

    @media (min-width: $tabletWidth) {
      row-gap: 45px;
      margin-bottom: 55px;
    }
  }
  .howImg {
    border-radius: $borderRadiusBig;
    margin-bottom: 35px;

    @media (min-width: $tabletWidth) {
      margin-bottom: 62px;
    }
  }
  .stepsList {
    margin-bottom: 40px;

    @media (min-width: $tabletWidth) {
      margin-bottom: 60px;
    }
  }
  .stepsTitle {
    margin-bottom: 5px;

    @media (min-width: $tabletWidth) {
      margin-bottom: 20px;
    }
  }

  .reviewsSection {
    padding-bottom: 12px;

    @media (min-width: $tabletWidth) {
      padding-bottom: 30px;
    }

    @media (min-width: $laptopWidth) {
      padding-bottom: 30px;
    }
  }

  .quoteList {
    gap: 20px;
    columns: 1;

    @media (min-width: $tabletWidth) {
      columns: 2;
    }
    @media (min-width: $laptopWidth) {
      columns: 3;
    }
  }

  .tarifList {
    @include grid();
    margin-bottom: 35px;
    @media (min-width: $tabletWidth) {
      margin-bottom: 55px;
    }
  }
}

.wrap {
  max-width: 620px;
}

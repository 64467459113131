@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.block {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-top: 20px;
  width: 100%;
}

.list {
  @include reset-list;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 9px;
}

.link {
  @include buttonArea(5px);
  display: block;
  width: 20px;
  height: 20px;
}

@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.form {
  .smsInput {
    width: 40px;
    padding: 10px 5px;
    text-align: center;
  }
}

.title {
  margin: 0;
  margin-bottom: 13px;
  font-size: 18px;
  line-height: 20px;

  &.reg {
    margin-bottom: 16px;
  }
}

.descr {
  margin-top: 0;
  margin-bottom: 15px;
  line-height: 20px;
}

.input {
  width: 100%;
  margin-bottom: 20px;
  &.name {
    margin-bottom: 10px;
  }
}

.changePhoneBtn {
  @include reset-btn;
  font-weight: bold;
  color: $colorBlack;
}

.changeAuthOption {
  @include reset-btn;
  font-weight: bold;
  color: $colorBlack;
  margin-left: 20px;
}

.smsWrap {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.smsInputs {
  display: flex;
  align-items: center;
  gap: 10px;
}

.smsError {
  text-align: left;
  color: rgba($colorRed, 0.5);
  font-size: 11px;
  line-height: 14px;
  margin-left: 20px;
}

.footerBtn {
  display: flex;
  align-items: center;
}

.time {
  margin-left: 20px;
  font-size: 11px;
  line-height: 15px;
  color: rgba($colorBlack, 0.5);
}

.offer {
  margin-left: 0;
  font-size: 11px;
  // font-weight: 700;
  // text-decoration: underline;
  line-height: 15px;
  color: rgba($colorBlack, 0.5);
  cursor: pointer;
}

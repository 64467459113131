@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.selectData {
  flex-wrap: wrap;
  .title {
    margin-bottom: 7px;
    width: 100%;
  }
  .subtitle {
    text-transform: capitalize;
    font-size: 11px;
    line-height: 15px;
  }
}

.loaderText {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba($colorBlack, 0.5);
}

@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

@mixin transparent {
  background-color: transparent;
  color: $colorBlack;
  fill: $colorBlack;
}

.button {
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 10px 18px;
  border-radius: 30px;
  font-size: 14px;
  background-color: $colorBlack;
  color: $colorWhite;
  fill: $colorWhite;
  font-weight: bold;
  font-family: $mainFontName;
  border: 2px solid $colorBlack;
  flex-shrink: 0;
  cursor: pointer;
  transition: none;

  &.active,
  &.disabled,
  &:active,
  &:disabled {
    background-color: $colorGray;
    color: $colorGrayIcon;
    fill: $colorGrayIcon;
    border-color: $colorGray;
  }

  &.disabled,
  &:disabled {
    cursor: default;
    pointer-events: none;
    user-select: none;
  }
}

.transparent {
  @include transparent;
  border-color: transparent;

  &:active,
  &:disabled {
    background-color: transparent;
    border-color: transparent;
    color: inherit;
    fill: currentColor;
  }

  &:disabled {
    color: #727272;
  }
}

.outline {
  @include transparent;
  max-height: 40px;
  border: 2px solid $colorBlack;

  // &.active,
  // &:active,
  // &:focus,
  &:disabled {
    background-color: transparent;
    border: 2px solid $colorBlack;
  }
}

.fullWidth {
  width: 100%;
}

.withIcon {
  padding: 8px 18px 8px 14px;
  gap: 5px;
  text-align: left;

  .icon {
    flex-shrink: 0;
  }
}

.withMarginTop10 {
  margin-top: 10px;
}

.withMarginTop25 {
  margin-top: 25px;
}

.empty {
  border: none;
  background-color: transparent;
  color: $colorBlack;
  fill: $colorBlack;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;

  &:active,
  &:disabled {
    background-color: transparent;
    color: $colorGrayIcon;
    fill: $colorGrayIcon;
  }
}

.nopadding {
  padding: 0;
  border: 0;
  outline: none;
}

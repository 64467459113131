@import '/src/app/styles/vars.scss';

.rating {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.wrap {
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;

  &.min {
    width: 70px;
    height: 10px;

    $defaultOffset: 5px;
    @for $i from 1 through 5 {
      .star-#{$i} {
        left: -$defaultOffset * $i;
      }
    }
  }
  &.big {
    width: 96px;
    height: 16px;

    $defaultOffset: 8px;

    @for $i from 1 through 5 {
      .star-#{$i} {
        @if $i == 1 {
          left: -$defaultOffset * $i;
        } @else {
          left: (-$defaultOffset * $i);
        }
      }
    }

    $offsets: (
      '1': '8',
      '2': '20',
      '3': '32',
      '4': '44',
      '5': '56',
    );

    @each $i, $offset in $offsets {
      .star-#{$i} {
        left: -#{$offset}px;
      }
    }
  }
}
.star {
  position: relative;
  display: block;
  flex-shrink: 0;
  fill: rgba($colorBlack, 0.2);

  &.active {
    fill: $colorBlack;
  }
}

@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.authBlock {
  position: relative;

  &.header {
    @media (min-width: $tabletWidth) {
      top: -8px;
      margin-right: 0;
      // right: -33px;
    }

    @media (min-width: $laptopWidth) {
      right: 0;
      margin-left: 35px;
    }
  }
  .btns {
    display: flex;
    gap: 5px;
  }
  .popper {
    opacity: 1;
    padding: 0;
    background: none;
    z-index: 12;
    color: $colorBlack;
  }
}

.body-wrapper {
  min-height: 100vh;
}

.f-line-top {
  position: fixed;
  top: 1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.f-line-bottom {
  position: fixed;
  bottom: 1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.empty-footer {
  padding-bottom: 34px;
}

@import '/src/app/styles/vars.scss';

.logo {
  flex-shrink: 0;

  &.cabinet {
    display: block;
    margin-right: auto;
    width: 140px;
    height: 45px;

    @media (min-width: $tabletWidth) {
      width: 255px;
      height: 30px;
      display: flex;
      align-items: flex-end;
    }

    @media (min-width: $laptopWidth) {
      margin-right: 65px;
    }
  }

  &.index {
    margin-right: 0;

    @media (min-width: $tabletWidth) {
      // margin-right: 86px;
      margin-right: auto;
    }
  }
}

.link {
  position: relative;

  // &::before {
  //   content: '';
  //   position: absolute;
  //   left: 225px;
  //   bottom: 41px;
  //   z-index: -1;
  //   display: block;
  //   width: 100px;
  //   height: 7px;
  //   background-color: #ccc;
  // }
}

.img {
  &.index {
    max-width: 140px;

    @media (min-width: $tabletWidth) {
      max-width: 230px;
    }
  }
  &.cabinet {
    max-width: 140px;

    @media (min-width: $tabletWidth) {
      max-width: 185px;
    }
  }
}

.copy {
  position: relative;
  left: 46px;
  top: 0px;
  white-space: nowrap;
  color: rgba($colorBlack, 0.5);

  &.index {
    font-size: 9px;
    line-height: 15px;
    left: 25px;
    top: -4px;

    @media (min-width: $tabletWidth) {
      font-size: 11px;
      left: 46px;
      top: -2px;
    }
  }

  &.cabinet {
    font-size: 9px;
    left: 25px;
    top: -3px;

    br {
      display: none;
    }
    @media (min-width: $tabletWidth) {
      top: 0;
      left: 0;
      max-width: 55px;
      margin-left: 15px;
      margin-bottom: 2px;

      br {
        display: block;
      }
    }
  }

  @media (min-width: $tabletWidth) {
    font-size: 11px;
    line-height: 11px;
  }
}

@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.videoWrap {
  position: relative;
  margin-bottom: 35px;
  border-radius: 10px;
  background-color: #fafafa;
  overflow: hidden;

  @media (min-width: $tabletWidth) {
    margin-bottom: 50px;
  }
}

.videoBlock {
  position: relative;
  margin-bottom: 37px;
  border-radius: 10px;
  overflow: hidden;

  @media (min-width: $tabletWidth) {
    margin-bottom: 50px;
  }
  @media (min-width: $laptopWidth) {
    margin-bottom: 46px;
  }
}
.videoImgWrap {
  position: absolute;
  z-index: 1;
  top: 22.5%;

  @media (min-width: $tabletWidth) and (max-width: $laptopWidth) {
    top: 60px;
  }
}

.progress {
  display: none;

  @media (min-width: $tabletWidth) {
    display: block;
  }
}
.videoImgFirst {
  left: 8.5%;

  @media (min-width: $tabletWidth) and (max-width: $laptopWidth) {
    left: 40px;
  }
}
.videoImgSecond {
  right: 8.5%;

  @media (min-width: $tabletWidth) and (max-width: $laptopWidth) {
    right: 40px;
  }
}

.imgsGroup {
  visibility: hidden;
  opacity: 0;
  // transition: visibility, opacity 0.5s ease;

  &.visible {
    visibility: visible;
    opacity: 1;
  }
}

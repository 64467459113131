@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.item {
  width: 100%;
  border-radius: $borderRadiusBig;
  background-color: rgba(0, 0, 0, 0.02);
  padding: 25px 30px 30px;

  .title {
    margin-bottom: 0px;

    @media (min-width: $tabletWidth) {
      margin-bottom: 10px;
    }
  }

  .price {
    display: block;
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 10px;

    @media (min-width: $tabletWidth) {
      margin-bottom: 20px;
    }
  }

  .descr {
    // margin-bottom: 17px;
    p {
      margin: 0 0 10px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
  }

  .checkItem {
    display: flex;
    gap: 10px;
  }
}

@import '/src/app/styles/vars.scss';

.alert {
  background-color: $colorRed;
  padding: 10px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.alert__wrapper {
  position: relative;
}

.alert__link {
  color: $colorWhite;
  fill: currentColor;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  display: flex;
  align-items: flex-start;
  transition: all 500ms ease;
  transform: translateY(0);
  justify-content: center;
}

.alert__link--hide {
  position: absolute;
  opacity: 0;
  transform: translateY(-100%);
}

.alert__link svg {
  position: absolute;
  left: 0;
}

.alert__message {
  padding-left: 25px;
}

@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.wrap {
  display: flex;
  flex-direction: column;
}

.wrapImage {
  width: 60px;
  height: 60px;
  @media (min-width: $tabletWidth) {
    width: 100px;
    height: 100px;
  }
  position: relative;
  border-radius: $borderRadius;
  @include imgWrap;

  &.noMin {
    width: 100px;
    height: 100px;
  }

  &.error {
    box-shadow: 0px 0px 0px 1px rgba($colorRed, 0.5);
  }
}

.text {
  margin-top: 6px;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}

.grid {
  display: flex;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 28px;
}

.rating {
  margin-top: 5px;
}

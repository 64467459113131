@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local('Inter'), local('Inter'),
    url('../fonts/Inter-Regular.woff2') format('woff2'),
    url('../fonts/Inter-Regular.woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: local('Inter'), local('Inter-Bold'),
    url('../fonts/Inter-Bold.woff2') format('woff2'),
    url('../fonts/Inter-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: local('Inter'), local('Inter-Black'),
    url('../fonts/Inter-Black.woff2') format('woff2'),
    url('../fonts/Inter-Black.woff') format('woff');
}

@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.section {
  padding-top: 30px;
  padding-bottom: 40px;

  @media (min-width: $tabletWidth) {
    padding-top: 45px;
    padding-bottom: 60px;
  }

  &.bordered {
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  }

  &.first {
    padding-top: 0;
  }

  &.last {
    padding-bottom: 0;
  }
}

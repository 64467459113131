@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.title {
  margin: 0;
  margin-bottom: 38px;
}

.item {
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0;
  }

  &:empty {
    display: none;
  }
}

@import '/src/app/styles/vars.scss';

.textarea {
  width: 100%;
  min-height: 160px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 30px;
  font-size: 14px;
  line-height: 20px;
  background-color: transparent;
  color: $colorBlack;
  border-radius: 5px;

  resize: none;

  font-family: $mainFontName;
  border: 1px solid $borderInputColor;
  outline: 1px solid transparent;
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus {
    border-color: $colorBlack;
    outline: 1px solid $colorBlack;
  }

  &:disabled {
    background-color: $colorGray;
    border-color: $colorGray;
    color: rgba($colorBlack, 0.5);
    fill: $colorGrayIcon;
  }
  &--error {
    border-color: $inputErrorBorder;
  }

  &--transparent {
    background-color: transparent;
    color: $colorBlack;
    fill: $colorBlack;
  }

  @media (min-width: $tabletWidth) {
    min-height: 80px;
  }
}

.wrap {
  position: relative;
}

.counter {
  display: block;
  margin-top: 7px;
  font-size: 11px;
  line-height: 20px;
  color: rgba($colorBlack, 0.5);
  text-align: left;
}

@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.title {
  margin-bottom: 25px;

  @media (min-width: $tabletWidth) {
    margin-bottom: 40px;
  }
}
.descr {
  margin-bottom: 30px;

  @media (min-width: $tabletWidth) {
    margin-bottom: 33px;
  }
}

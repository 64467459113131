@import '/src/app/styles/vars.scss';

.title {
  position: relative;
  font-weight: 900;
  margin: 0;

  &.h1 {
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 35px;

    @media (min-width: $tabletWidth) {
      font-size: 46px;
      line-height: 50px;
      margin-bottom: 60px;
    }
  }

  &.h2 {
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 35px;

    @media (min-width: $tabletWidth) {
      font-size: 37px;
      line-height: 40px;
      margin-bottom: 40px;
    }
  }

  &.h3 {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 10px;

    @media (min-width: $tabletWidth) {
      font-size: 28px;
      line-height: 30px;
      margin-bottom: 20px;
    }
  }

  &.middle {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 10px;

    @media (min-width: $tabletWidth) {
      font-size: 37px;
      line-height: 40px;
      margin-bottom: 29px;
    }
  }
}

@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.imgWrap {
  --size: 60px;

  @media (min-width: $tabletWidth) {
    --size: 220px;
  }

  &.video {
    @media (min-width: $tabletWidth) {
      --size: 140px;
    }

    @media (min-width: $laptopWidth) {
      --size: 180px;
    }
  }
  @include imgWrap;
  width: var(--size);
  height: var(--size);
  border-radius: $borderRadiusBig;
  gap: 20px;

  @media (min-width: $tabletWidth) {
    margin-bottom: 20px;
  }

  // Если 4 изображения
  &--quad {
    display: grid;
    grid-template: repeat(2, 1fr) / repeat(2, 1fr);
  }
  // Если 9 изображений
  &--nine {
    display: grid;
    grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  }
}

.imgWrap.index {
  @media (min-width: 480px) and (max-width: $mobileWidth) {
    --size: 80px;
  }

  @media (min-width: $mobileWidth) and (max-width: $tabletWidth) {
    --size: 100px;
  }
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: $tabletWidth) {
    display: block;
  }

  .imgWrap {
    order: 2;
  }
  .progress {
    --height: 10px;
  }
}

.wrap.index {
  display: block;
}

.progressWrap {
  width: calc(100% - 60px - 20px);
  @media (min-width: $tabletWidth) {
    width: 100%;
  }
}

.progressCount {
  display: block;
  margin-top: 9px;
  font-size: 14px;
  line-height: 1;
  text-align: left;
}

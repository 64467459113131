@import '/src/app/styles/vars.scss';
@import '/src/app/styles/mixins.scss';

.item {
  display: flex;
  flex-direction: column;

  .title {
    margin-bottom: 10px;

    @media (min-width: $tabletWidth) {
      margin-bottom: 20px;
    }
  }
  .descr {
    margin-bottom: 24px;
    padding-right: 20px;
  }
  .images {
    margin: 0;
  }
}
